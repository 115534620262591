<template>
  <v-data-table
    :headers="headers"
    :items="employees"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              สร้างพนักงาน
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                  <v-row>
                    <v-col
                      v-if="editedItem.employee_img"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-img
                      :src="editedItem.employee_img"
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        label="รูปพนักงาน"
                        v-model="editedItem.file"></v-file-input>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.file.$silentErrors"
                        v-if="v$.editedItem.file.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        type="email"
                        v-model="editedItem.employee_email"
                        label="อีเมล"
                      ></v-text-field>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.employee_email.$silentErrors"
                        v-if="v$.editedItem.employee_email.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        type="password"
                        v-model="editedItem.password"
                        label="รหัสผ่าน"
                      ></v-text-field>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.password.$silentErrors"
                        v-if="v$.editedItem.password.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        type="password"
                        v-model="editedItem.password_confirmation"
                        label="ยืนยันรหัสผ่าน"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_user"
                        label="ชื่อผู้ใช้งาน"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-select
                        v-model="editedItem.employee_type_id"
                        :items="employee_types"
                        item-text="employee_type_name"
                        item-value="employee_type_id"
                        dense
                        label="กลุ่มพนักงาน"
                      >
                      </v-select>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.employee_type_id.$silentErrors"
                        v-if="v$.editedItem.employee_type_id.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        type="text"
                        v-model="editedItem.employee_name"
                        label="ชื่อ-นามสกุล"
                      ></v-text-field>
                      <small
                        class="validate-err"
                        v-for="(err,index) in v$.editedItem.employee_name.$silentErrors"
                        v-if="v$.editedItem.employee_name.$error">{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_tele"
                        label="เบอร์โทรศัพท์"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-textarea
                        v-model="editedItem.employee_shop_address"
                        label="ที่อยู่"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_line_id"
                        label="Line Id"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_facebook_url"
                        label="Facebook URL"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_instragram"
                        label="Instagram"
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.employee_identification"
                        label="เลขบัตรประชาชน"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>

    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-container
        class="px-0"
        fluid
      >
        <v-checkbox
          value="Y"
          dense
          class="mt-0"
          hide-details
          @change="changeStatus(item.employee_id)"
          v-model="item.user.status"
        ></v-checkbox>
      </v-container>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '@/axios.service'
import instance_employee from '@/services/employee'
import instance_employee_type from '@/services/employeeType'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, sameAs} from '@vuelidate/validators'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,

    },
    total : 0,
    employees: [],
    employee_types: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'ชื่อ-นามสกุล', value: 'employee_name'},
      {text: 'อนุมัติ(เปิด/ปิด)', value: 'status', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_logo: null,
      employee_name: '',
      employee_email: '',
      employee_user: null,
      employee_type_id: null,
      employee_tele: null,
      employee_shop_address: '',
      employee_line_id: '',
      employee_facebook_url: '',
      employee_instragram: '',
      employee_identification: '',
      password: '',
      password_confirmation: '',
    },
    defaultItem: {
      employee_logo: null,
      employee_name: '',
      employee_email: '',
      employee_user: null,
      employee_type_id: null,
      employee_tele: null,
      employee_shop_address: '',
      employee_line_id: '',
      employee_facebook_url: '',
      employee_instragram: '',
      employee_identification: '',
      password: '',
      password_confirmation: '',
    },
  }),
  validations() {

    var validate = {
      editedItem: {
        employee_email: {required ,email},
        employee_name: {required},
        file: { },
        employee_type_id: { required },
        password: { },
        password_confirmation: { },
      }
    }
    if (this.editedIndex == -1) {
      validate.editedItem.file = { required};
      validate.editedItem.password = { required, minLength: minLength(8) };
      validate.editedItem.password_confirmation = {sameAsPassword: sameAs(this.editedItem.password)};
    }
    return validate;
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม พนักงาน' : 'แก้ไข พนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getEmployee()
      },
    },
    search : {
      handler(){
        this.getEmployee()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getEmployee()
    this.getEmployeeType()
  },

  methods: {
    changeStatus(id) {
      this.$store.state.isLoading = true;
      instance_employee.employeeUpdateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false;
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false;
      });
    },
    getEmployee() {
      this.$store.state.isLoading = true;
      var data = {
        search : this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }
      instance_employee.getEmployee(data).then(res => {
        this.employees = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;
      });
    },
    getEmployeeType() {
      this.$store.state.isLoading = true;
      var data = {
        search : '',
        size : '',
        page : '',
      }
      instance_employee_type.getAll(data).then(res => {
        this.employee_types = res.data.data
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;
      });
    },
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.employees.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.employees.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true;
      instance_employee.employeeDestroy(this.editedItem.employee_id)
        .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150);
          this.getEmployee()
          this.closeDelete()
        }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false;

      });
    },

    close() {
      this.dialog = false
      this.v$.$reset();
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true;

      if (this.editedIndex > -1) {
        instance_employee.employeeUpdate(this.editedItem).then(res => {
          if (res.data.success) {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150);
            this.getEmployee()
            this.close()
          }else{

            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: res.message ?? res.data.message,
            })
          }

        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_employee.employeeStore(this.editedItem).then(res => {
          if (res.data.success) {
            this.getEmployee()
            this.close()

            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150);
          }else{

            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: res.message ?? res.data.message,
            })
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false;
        });
      }
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
